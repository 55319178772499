import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './components/home/home.component';
import { UnderconstructionComponent } from './components/underconstruction/underconstruction.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [ MsalGuard ] },
  { path: 'manage-standards', loadChildren: () => import('./manage-standards/manage-standards.module').then(m => m.ManageStandardsModule) },
  { path: 'ingredients', loadChildren: () => import('./ingredients/ingredients.module').then(m => m.IngredientsModule) },
  { path: 'manage-recipes', loadChildren: () => import('./manage-recipes/manage-recipes.module').then(m => m.ManageRecipesModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'underconstruction', component: UnderconstructionComponent, canActivate: [ MsalGuard ] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home ', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
